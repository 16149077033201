.App {
  text-align: center;
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;

  h1{
    margin: 0.5em auto;
    font-size: 2.5em;
  }
  
  #start {
    position: relative;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    .text{
      position: absolute;
      top: 10%;
    }

    img{
      position: absolute;
      height: 330px;
      left: 0;
      right: 0;
      margin: 50px auto;
      cursor: pointer;
    }

  }

  #finish{
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: black;
    top: 0;
    transition: top 1.5s ease;

    h1{
      color: white;
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
    }

    video{
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      margin: 0 auto;
      z-index: 100;
      width: 100%;
      height: 100%;
      background-color: black;
      transition: width 1.5s ease, height 1.5s ease, top 1.5s ease;
    }

    h2{
      position: absolute;
      color: white;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 300px;
    }
    .text{
      img{
        position: absolute;
        height: 100px;
        bottom: 180px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    .sub{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: fit-content;
      h2{
        position: static;
        display: block;
      }
      .youtubeSubscribe{
        display: inline-flex;        
      }
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#social_div{
  width: 20vw;
  text-align: left;
}

.social_span{
  white-space: nowrap;
  margin-top: 8px;
  height: fit-content;
  display: inline-block;
}

a{
  color: white;
}
a:visited {
  color: white;
}

#footer{
  font-size: small;
  position: absolute;
  bottom: 0;
}